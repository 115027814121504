import React, { useState } from 'react';
import { css, cx } from '@emotion/css';
import * as styles from './AlertScheme';
import { Result, extractIdentifier, formatTimestamp } from './utils';

interface AlertProps {
  dbData: Result[];
  width: number;
  height: number;
  options: {
    text: string;
    showSeriesCount: boolean;
    seriesCountSize: 'sm' | 'md' | 'lg';
    X: number;
    Y: number;
    Zoom: number;
  };
}

const AlertTable: React.FC<AlertProps> = ({ dbData, width, height, options }) => {
  const [viewMode, setViewMode] = useState<'live' | 'historic'>('live');
  const [eventId, setEventId] = useState<string>('');

  const handleViewModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setViewMode(event.target.value as 'live' | 'historic');
  };

  const alertBoxStyle = css`
    background: #012b39;
    border-radius: 0.25em;
    overflow: auto;
    width: ${width}px;
    height: ${height}px;
  `;

  const flashingAnimation = css`
    @keyframes flashing {
      0% {
        opacity: 1;
      }
      50% {
        opacity: 0.5;
      }
      100% {
        opacity: 1;
      }
    }
  `;

  const getRowStyle = (scheme: string | undefined, status: number | undefined) => {
    let style = css``;

    if (scheme?.includes('red_urgent')) {
      style = css`
        background: darkred;
      `;
    } else if (scheme?.includes('orange_urgent')) {
      style = css`
        background: rgb(143, 61, 13);
      `;
    } else if (scheme?.includes('yellow_urgent')) {
      style = css`
        background: rgb(129, 135, 9);
      `;
    } else if (scheme?.includes('red')) {
      style = css`
        background: rgb(166, 7, 7);
      `;
    } else if (scheme?.includes('yellow')) {
      style = css`
        background: rgb(183, 191, 17);
      `;
    } else if (scheme?.includes('orange')) {
      style = css`
        background: rgb(191, 86, 25);
      `;
    }

    if (status === 1) {
      return css`
        ${style}
        animation: flashing 1s infinite;
      `;
    }

    return style;
  };

  // Filter dbData based on live or historic selection
  const filteredData = dbData.filter((event) => {
    if (viewMode === 'live') {
      return event.status === 0 || event.status === 1
    } else if (viewMode === 'historic' && eventId) {
      const eventIdStr = eventId.toString(); // Convert eventId to string
      if (event.eventID !== undefined) { // Check if event.eventID is defined
        const eventIDStr = event.eventID.toString(); // Convert event.eventID to string
        return eventIDStr.includes(eventIdStr); // Check if eventID contains eventId input
      }
      return false; // If event.eventID is undefined, exclude it from results
    }
    return true;
  });

  return (
    <div className={alertBoxStyle}>
      <div className={styles.containerStyle}>
        <label className={styles.labelStyle}>
          <input
            type="radio"
            value="live"
            checked={viewMode === 'live'}
            onChange={handleViewModeChange}
          />
          Live Events
        </label>
        <label className={styles.labelStyle}>
          <input
            type="radio"
            value="historic"
            checked={viewMode === 'historic'}
            onChange={handleViewModeChange}
          />
          Historic Events
        </label>

        {viewMode === 'historic' && (
          <input
            type="text"
            placeholder="Enter Event ID"
            value={eventId}
            onChange={(e) => setEventId(e.target.value)}
            className={styles.inputStyle}
          />
        )}
      </div>

      <div>
        <table className={styles.tableStyle}>
          <thead>
            <tr
              style={{
                position: 'sticky',
                top: '0',
                background: '#012b39',
                zIndex: 1,
              }}
            >
              <th className={styles.thStyle}>Event ID</th>
              <th className={styles.thStyle}>Spacecraft</th>
              <th className={styles.thStyle}>Record Time</th>
              <th className={styles.thStyle}>Message</th>
              <th className={styles.thStyle}>Accepted Time</th>
              <th className={styles.thStyle}>Mnemonic</th>
              <th className={styles.thStyle}>Scheme</th>
              <th className={styles.thStyle}>Status</th>
            </tr>
          </thead>
          <tbody className={styles.tbodyStyle}>
            {filteredData.map((value, index) => {
              const rowClass = getRowStyle(value.scheme, value.status);
              return (
                <tr key={index}>
                  <td className={styles.tdStyle}>{value.eventID}</td>
                  <td className={styles.tdStyle}>{value.clientID}</td>
                  <td className={styles.tdStyle}>
                    {value.recordedTime ? formatTimestamp(value.recordedTime) : 'N/A'}
                  </td>
                  <td className={styles.tdStyle}>{value.message}</td>
                  <td className={styles.tdStyle}>
                    {value.acceptedTime ? formatTimestamp(value.acceptedTime) : 'N/A'}
                  </td>
                  <td className={styles.tdStyle}>
                    {value.message ? extractIdentifier(value.message) : 'N/A'}
                  </td>
                  <td className={cx(styles.tdStyle, rowClass)}>{value.scheme}</td>
                  <td className={styles.tdStyle}>{value.status}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AlertTable;
