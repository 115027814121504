import React, { useCallback } from 'react';
import { PanelProps } from '@grafana/data';
import {SimpleOptions } from 'types';
import { css, cx } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';
import { PanelDataErrorView } from '@grafana/runtime';
import AlertTable from './AlertTable';
import { Result } from './utils';

interface Props extends PanelProps<SimpleOptions> {}

const getStyles = () => {
  return {
    wrapper: css`
      fontFamily: Open Sans;
      position: relative;
    `,
    svg: css`
      position: absolute;
      top: 0;
      left: 0;
    `, 
    textBox: css`
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px;
    `,
  };
};

type Field = {
  name: string;
  type: string;
  values: any[]; // Replace 'any' with a more specific type if possible
};

export const SimplePanel: React.FC<Props> = ({options, data, width, height, fieldConfig, id }) => {

 
  const styles = useStyles2(getStyles);

  const exampleFields: Field[] = [
    { name: "", type: "", values: []}
  ];

  const selectedData =  data && data.series[0] ? data.series[0].fields : exampleFields;

  const getDataFromSource = useCallback(() => {
    const recordTimeColumn = selectedData.find(column => column.name === "recordedTime");
    // Safely get the other columns
    const eventIDColumn = selectedData.find(column => column.name === "eventID");
    const acceptedTimeColumn = selectedData.find(column => column.name === "acceptedTime");
    const messsageColumn = selectedData.find(column => column.name === "message");
    const statusColumn = selectedData.find(column => column.name === "status");
    const groupColumn = selectedData.find(column => column.name === "group");
    const clientIDColumn = selectedData.find(column => column.name === "clientID");
    const schemeColumn = selectedData.find(column => column.name === "scheme");
    const sourceColumn = selectedData.find(column => column.name === "source");

    const eventIDValues = eventIDColumn ? eventIDColumn.values : [];
    const recordedTimeValues = recordTimeColumn ? recordTimeColumn.values : [];
    const acceptedTimeValues = acceptedTimeColumn ? acceptedTimeColumn.values : [];
    const messageValues = messsageColumn ? messsageColumn.values : [];
    const statusValues = statusColumn ? statusColumn.values : [];
    const groupValues = groupColumn ? groupColumn.values : [];
    const clientIDValues = clientIDColumn ? clientIDColumn.values : [];
    const schemeValues = schemeColumn ? schemeColumn.values : [];
    const sourceValues = sourceColumn ? sourceColumn.values : [];

    const result: Result[] = [];

    for (let i = 0; i < recordedTimeValues.length; i++) {
      result.push({
        eventID: eventIDValues[i] || "",
        recordedTime: recordedTimeValues[i] || "",
        acceptedTime: acceptedTimeValues[i] || "",
        message: messageValues[i] || "",
        status: statusValues[i] || "", 
        group: groupValues[i] || "",
        clientID: clientIDValues[i] || "",
        scheme: schemeValues[i] || "",
        source: sourceValues[i] || ""
      });
    }
  
    return result;
  },[selectedData]);

  const testData = getDataFromSource();

  if (data.series.length === 0) {
    return <PanelDataErrorView fieldConfig={fieldConfig} panelId={id} data={data} needsStringField />;
  }

  return (
    <div       
    className={cx(
      styles.wrapper,
      css`
        width: ${width}px;
        height: ${height}px;
      `
    )}>
 <AlertTable dbData={testData} width={width} height={height} options={options}></AlertTable>

    </div>
   ); 
};
 


/* query incase needed
select recordedTime, aTime as "acceptedTime", message, status,clientID, source, groups.name AS "group", scheme 
from events, alertingEvents, groups 
where events.id=alertingEvents.eventID and alertingEvents.gid = groups.gid and groups.name like '%themis%'
ORDER BY events.id desc
*/ 
