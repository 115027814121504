export type InnerDataValue = {
  telemetry: number;
  unit: string;
  limit: LimitProp;
  fname: string;
  mname: string;
  value_color: string;
  dbData: Dbdata;
  spacecraft: string;
};

export type InnerData = {
  [key: string]: InnerDataValue;
};

export interface LimitProp{
  rl: number | null;
  yl: number | null;
  yh: number | null;
  rh: number | null;
}

export interface Dbdata{
  telemetry: number;
  unit: string;
  //limit: LimitProp;
  limit: string;
  live: string;
  mne: string;
  spacecraft: string;
}

export interface TbProps {
    data: {
      name: string;
      value: InnerData;
      textbox_item_class: string;
      textbox_container_class: string;
      id: string;
      backgroundImage: string;
      power: TLM;
      power1: TLM;
      powerName: string;
      power1Name: string;
    };
}

export interface TLM {
  telemetry: string;
  unit: string;
  limit: string;
  live: string;
  mne: string;
  spacecraft: string;
}

export function determineColorNew(name: string){
  if (name === 'rl' || name === 'rh' || name === 'RH' || name === 'RL'){
    return 'red-limit';
  }
  else if (name === 'yl' || name === 'yh' || name === 'YH' || name === 'YL'){
      return 'yellow-limit';
  }else{
    return 'gray-limit';
  }
}

export function extractIdentifier(message: string) {
  // Pattern for the first format (IVMON_ESASDHSFJ35V)
  const pattern1 = /violation\s+(\w+)/;
  
  // Pattern for the second format (PIDPUDGDPWRA)
  const pattern2 = /RED_LOW:(\w+)/;

  const pattern3 = /RED_HIGH:(\w+)/;
  
  // Try the first pattern
  let match = message.match(pattern1);
  if (match) {
    return match[1];
  }
  
  // If first pattern doesn't match, try the second
  match = message.match(pattern2);
  if (match) {
    return match[1];
  }

  // If first pattern doesn't match, try the second 
  match = message.match(pattern3);
  if (match) {
    return match[1];
  }
  
  // If no match found, return null or some default value
  return null;
}

export function formatTimestamp(unixTimestamp: string): string {
  const date = new Date(unixTimestamp); // Convert seconds to milliseconds

  const year = date.getUTCFullYear();
  
  // Get day of year
  const startOfYear = new Date(Date.UTC(year, 0, 1));
  const diff = date.getTime() - startOfYear.getTime(); // Use getTime() to ensure we get a number
  const oneDay = 1000 * 60 * 60 * 24;
  const dayOfYear = Math.floor(diff / oneDay) + 1;

  const hours = date.getUTCHours().toString().padStart(2, '0');
  const minutes = date.getUTCMinutes().toString().padStart(2, '0');
  const seconds = date.getUTCSeconds().toString().padStart(2, '0');

  return `${year}:${dayOfYear.toString().padStart(3, '0')}:${hours}:${minutes}:${seconds}`;
}



export interface Result {
  recordedTime?: string;
  acceptedTime?: string;
  message?: string;
  status?: number;
  group?: string;
  clientID?: string;
  scheme?: string;
  source?: string;
  eventID?: number;
}
